<template>
  <div>
    <a-modal :width="524" v-model="visible" title="需求类型">
      <p style="display: flex; text-align: right">
        <span style="width: 68px">类型：</span
        >{{ $store.state.home.modalParms.title }}
      </p>
      <p>
        <span class="text"><span>*</span>标题：</span
        ><a-input
          v-model="title"
          style="width: 400px"
          placeholder="请输入标题"
        />
      </p>
      <p style="display: flex">
        <span class="text"><span>*</span>内容：</span
        ><a-textarea
          v-model="content"
          style="width: 400px"
          placeholder="请输入内容"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </p>
      <p>
        <span class="text">联系人：</span
        ><a-input
          v-model="contact"
          style="width: 400px"
          placeholder="请输入联系人"
        />
      </p>
      <p>
        <span class="text"><span>*</span>手机号：</span
        ><a-input
          v-if="!cookie.getCookie('user')"
          v-model="tel"
          style="width: 400px"
          placeholder="请输入手机号"
        />
        <span class="text-phone" v-else>
          {{ JSON.parse(cookie.getCookie("user")).phone }}
        </span>
      </p>
      <p v-if="!cookie.getCookie('user')">
        <span class="text"><span>*</span>验证码：</span
        ><a-input
          v-model="code"
          style="width: 280px"
          placeholder="请输入验证码"
        />
        <span class="text"
          ><span
            style="
              border-radius: 10px;
              cursor: pointer;
              width: 100px;
              text-align: center;
              display: block;
            "
            class="msg__btn"
            @click="getSmsCode"
            >{{ state.smsSendBtn ? state.time : "获取验证码" }}</span
          >
        </span>
      </p>
      <div class="illustrate">
        <p>
          1、您提交需求后，系统会安排专业顾问在2小时内与您联系（非工作时间内会顺延至下一个
          工作日与您联系）。
        </p>
        <p>
          2、知识产权（专利、商标、版权）申请能否成功是由国家知识产权局决定，顾问会给您提
          供专业建议，平台及专业顾问都不能确保和承诺注册结果。
        </p>
        <p>
          3、知识产权申请均需要较长的周期，期间需要您配合专业顾问提供相应的资料，保持联系方
          式畅通，及时缴纳申请费用，实际申请周期由国家知识产权局决定，请做好安排。
        </p>
        <div class="check">
          <a-checkbox v-model="check" @change="onChange"></a-checkbox>
          <span class="text">我已阅读并同意</span
          ><span class="text1">《业务申报服务协议》</span>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="setNeeds" type="danger">提交</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { needs, smsCode } from "../api/index";
import cookie from "../utils/cookie";
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "horizontal_login" }),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        selectindex: 0,
        smsSendBtn: false,
        read: false,
      },
      falg: true,
      cookie,
      check: false,
      content: "",
      contact: "",
      title: "",
      tel: "",
      code: "",
      visible: false,
    };
  },
  methods: {
    async getSmsCode(e) {
      if (this.state.smsSendBtn) return this.$message.error("请勿重复提交验证码");
      if (this.tel === "") return this.$message.error("请填写手机号");
      e.preventDefault();
      const {
        form: { validateFields },
        state,
      } = this;
      const params = {
        phone: this.tel,
        useTo: 6,
      };
      const res = await smsCode(params);
      validateFields(["subForm[phone]"], { force: true }, (err) => {
        if (!err) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
          const hide = this.$message.loading("验证码发送中..", 0);
          // tianli
          if (res.code === 200) {
            setTimeout(() => {
              this.$message.success({
                content: "发送成功，请在手机中查看!",
                duration: 2,
              });
            }, 800);
          } else {
            setTimeout(() => {
              this.$message.success({
                content: "发送失败，请重新发送!",
                duration: 2,
              });
            }, 0);
          }
          setTimeout(hide, 2500);
        }
      });
    },
    onChange(e) {
      this.check = e.target.checked;
    },
    async setNeeds() {
      if (cookie.getCookie("user")) {
        this.tel = JSON.parse(cookie.getCookie("user")).phone;
        this.code = null;
      }
      if (this.check === false)
        return this.$message.error("请勾选业务申报服务协议！");
      if (this.title === "") return this.$message.error("请填写标题");
      if (this.tel === "") return this.$message.error("请填写手机号");
      if (this.content === "") return this.$message.error("请填写内容");
      if (this.code === "") return this.$message.error("请填写验证码");
      const params = {
        ownerId: cookie.getCookie("user")
          ? JSON.parse(cookie.getCookie("user")).id
          : null,
        // code: null,
        code: this.code,
        title: this.title,
        content: this.content,
        category: this.$store.state.home.modalParms.category,
        tel: this.tel,
        type: this.$store.state.home.modalParms.title,
        contact: this.contact,
      };
      const res = await needs(params);
      if (res.code === 200) {
        this.$message.success("提交需求成功！");
        this.visible = false;
        this.content = "";
        this.contact = "";
        this.tel = "";
        this.code = "";
        this.title = "";
        this.check = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    showModal() {
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.ant-modal-body {
  .illustrate {
    width: 406px;
    margin-left: 58px;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    .check {
      display: flex;
      align-items: center;
      .text {
        margin: 0 5px;
      }
      .text1 {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2680eb;
      }
    }
    > p {
      margin: 0;
    }
  }
  > p {
    .text {
      display: inline-block;
      width: 70px;
      text-align: right;
      > span {
        color: red;
      }
    }
    .text-phone {
      width: 84px;
    }
  }
}
</style>