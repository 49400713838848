<template>
  <div>
    <a-modal title="反馈建议" :visible="visible" @cancel="closure">
      <p style="display: flex; text-align: right">
        <span style="width: 68px"><span style="color: red">*</span>内容：</span
        ><a-textarea
          v-model="content"
          style="width: 400px"
          placeholder="请输入内容"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </p>
      <p style="display: flex; text-align: right">
        <span style="width: 68px">联系人：</span
        ><a-input
          v-model="name"
          style="width: 400px"
          placeholder="请输入联系人"
        />
      </p>
      <p style="display: flex; text-align: right">
        <span style="width: 68px"
          ><span style="color: red">*</span>手机号：</span
        ><a-input
          v-model="tel"
          style="width: 400px"
          placeholder="请输入手机号"
        />
      </p>
      <template slot="footer">
        <a-button @click="submit" type="danger">提交</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { guestbook } from "../api/index";
export default {
  name: "PatentPlatBrandMsgmodel",
  data() {
    return {
      content: "",
      name: "",
      tel: "",
      web_info: {
        default_consultant: {
          value: "",
        },
      },
      visible: false,
    };
  },

  mounted() {},

  methods: {
    async submit() {
      if (this.content === "")
        return this.$message.error("内容不能为空！");
        if (this.tel === "")
        return this.$message.error("手机号不能为空！");
      const params = {
        content: this.content,
        name: this.name,
        tel: this.tel,
      };
      const res = await guestbook(params);
      if (res.code === 200) {
        this.$message.success("提交需求成功！");
        this.visible = false;
        this.content = "";
        this.name = "";
        this.tel = "";
      }
    },
    closure() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>