<template>
  <div class="floating">
    <div class="customer">
      <div class="right-head">
        <img src="../assets/customer-logo.png" alt="" />
        <div class="text">专属顾问</div>
      </div>
      <div class="left-head" v-if="consultant">
        <div class="title">
          {{ consultant.name }}
        </div>
        <div class="content">
          <div class="left">
            <img
              style="width: 100px; height: 100px"
              :src="
                consultant.avatar
                  ? consultant.avatar
                  : require('../assets/avatar.png')
              "
              alt=""
            />
            <div class="text">电话：{{ consultant.tel }}</div>
          </div>
          <div class="right">
            <div class="vx">
              <img
                style="width: 100px; height: 100px"
                :src="consultant.qrcode"
                alt=""
              />
            </div>
            <div class="text">扫码添加微信</div>
          </div>
        </div>
      </div>
    </div>
    <div @click="$refs.msgtype.visible = true" class="form">
      <img src="../assets/form.png" alt="" />
    </div>
    <div>
      <a-back-top />
    </div>
    <MsgModel ref="msgtype" />
  </div>
</template>

<script>
import MsgModel from "./MsgModel.vue";
export default {
  name: "Floating",

  components: { MsgModel },

  directives: {},

  data() {
    return {};
  },
  props: ["consultant"],
  methods: {},
};
</script>

<style lang="less" scoped>
.floating {
  position: fixed;
  z-index: 1;
  right: 100px;
  bottom: 130px;
  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }
  .customer {
    width: 44px;
    height: 90px;
    background-image: url(../assets/customer.png);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    text-align: center;
    &:hover {
      .left-head {
        display: block;
      }
    }
    .right-head {
      img {
        margin-top: 12px;
      }
      .text {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #ffffff;
        padding: 3px;
      }
    }
    .left-head {
      display: none;
      width: 300px;
      position: absolute;
      height: 216px;
      background: #fff;
      top: -124px;
      left: -300px;
      transition: all 0.3s ease-out;
      border-radius: 4px;
      .title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        height: 40px;
        line-height: 40px;
        background: #f8f8f8;
        border: 1px solid #eeeeee;
        text-align: left;
        color: #333333;
        padding: 0 26px;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 81%;
        padding: 0 20px;
        .right {
          .vx {
            width: 100px;
            height: 100px;
            margin: auto;
          }
        }
      }
    }
  }
  .form {
    cursor: pointer;
    width: 44px;
    height: 44px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 4px;
    margin-top: 10px;
    text-align: center;
    line-height: 44px;
  }
}
</style>